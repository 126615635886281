import { ReactComponent } from '@/assets/icons/microsoft.svg';
import { CSSProperties } from 'react';

export interface UploadIconProps {
    height?: number;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
}

const Icon = ({ height = 20, style, className, onClick }: UploadIconProps) => {
    return (
        <ReactComponent
            height={height}
            style={{ minWidth: height, ...style }}
            className={className}
            onClick={onClick}
        />
    );
};
export default Icon;
