import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    createFirebaseUser,
    firebaseAuth,
    loginFirebaseUser,
    sendFbPasswordResetEmail,
    signInWithGoogle as signInWithGooglePopUp,
    signInWithMsfPopUp,
} from '../firebase';
import {
    onAuthStateChanged,
    User as FirebaseUser,
    linkWithCredential,
    OAuthCredential,
    unlink,
} from 'firebase/auth';
import * as api from '../api/services';
import { Plan, User } from '../types/types';
import Api from '@/api/client';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import * as Sentry from '@sentry/react';

export interface AuthContextValue {
    user: User | null;
    plan: Plan;
    isSuperUser: boolean;
    signInWithGoogle: (params?: { isApiDev?: boolean }) => Promise<void>;
    signInWithMSF: (params?: { isApiDev?: boolean }) => Promise<void>;
    signOut: () => void;
    hasLoadedUser: boolean;
    setUser: Dispatch<SetStateAction<User | null>>;
    signUpWithEmailPass: (user: SignUpWithEmailParams) => Promise<void>;
    signInWithEmailPass: (email: string, password: string) => Promise<void>;
    // resetPassword: (email: string) => Promise<void>;
    syncUser: () => Promise<void>;
    initLocalUserFromFbUser: (firebaseUser: FirebaseUser) => Promise<void>;
}

export interface SignUpWithEmailParams {
    email: string;
    password: string;
    name: string;
    isApiDev?: boolean;
}

const defaultValue: Partial<AuthContextValue> = {
    user: null,
    plan: 'FREE',
    hasLoadedUser: false,
};

// @ts-expect-error
const AuthContext = React.createContext<AuthContextValue>(defaultValue);

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [hasLoadedUser, setHasLoadedUser] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [plan, setPlan] = useState<Plan>('FREE');
    const [isSuperUser, setIsSuperUser] = useState(false);

    const hasCheckedUser = useRef(false);
    // const { reset } = useRequestContext();

    // used to detect user at initial page load (or refresh)
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            firebaseAuth,
            async (firebaseUser) => {
                if (hasCheckedUser.current) return;
                hasCheckedUser.current = true;

                if (firebaseUser) {
                    try {
                        await Api.init(firebaseUser);
                        const { data: user } = await api.getUser();
                        // Api.setSuperUserKey(user.superUserKey!);
                        setUser(user);
                        Sentry.setUser({
                            email: user.email,
                            id: user.id,
                            username: user.name,
                        });
                        setPlan(user.plan);
                        setIsSuperUser(Boolean(user?.features.SUPER));
                    } catch (error) {
                        Sentry.captureException(error);
                        signOut();
                    }
                }
                setHasLoadedUser(true);
            }
        );
        return unsubscribe;
    }, []);

    const signInWithGoogle = async ({
        isApiDev,
    }: { isApiDev?: boolean } = {}) => {
        let firebaseUserCopy;
        // https://github.com/firebase/firebase-js-sdk/issues/8061#issuecomment-2047370693
        const originalSetTimeout = window.setTimeout;
        //@ts-ignore
        window.setTimeout = function (fn, delay, ...args) {
            // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
            if (delay === 8000) {
                delay = 100;
            }
            return originalSetTimeout(fn, delay, ...args);
        };

        try {
            const { user: firebaseUser } = await signInWithGooglePopUp();
            firebaseUserCopy = firebaseUser;
            await Api.init(firebaseUser);
            const { data: user } = await api.getUser();
            setUser(user);
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });
            setPlan(user.plan);
            setIsSuperUser(Boolean(user?.features.SUPER));
        } catch (error) {
            if (
                error instanceof FirebaseError &&
                error.code === 'auth/popup-closed-by-user'
            ) {
                return;
            }

            if (error instanceof AxiosError && error.response?.status === 404) {
                try {
                    const { data: user } = await api.createUser({
                        name: firebaseUserCopy?.displayName!,
                        isApiDev,
                    });
                    setUser(user);
                    Sentry.setUser({
                        email: user.email,
                        id: user.id,
                        username: user.name,
                    });
                    return;
                } catch (error) {
                    signOut();
                    throw error;
                }
            }

            signOut();
            throw error;
        } finally {
            window.setTimeout = originalSetTimeout;
        }
    };

    const signInWithMSF = async ({ isApiDev }: { isApiDev?: boolean } = {}) => {
        let firebaseUserCopy;
        // https://github.com/firebase/firebase-js-sdk/issues/8061#issuecomment-2047370693
        const originalSetTimeout = window.setTimeout;
        //@ts-ignore
        window.setTimeout = function (fn, delay, ...args) {
            // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
            if (delay === 8000) {
                delay = 100;
            }
            return originalSetTimeout(fn, delay, ...args);
        };

        try {
            const { user: firebaseUser } = await signInWithMsfPopUp();
            firebaseUserCopy = firebaseUser;
            await Api.init(firebaseUser);
            const { data: user } = await api.getUser();
            setUser(user);
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });
            setPlan(user.plan);
            setIsSuperUser(Boolean(user?.features.SUPER));
        } catch (error) {
            if (
                error instanceof FirebaseError &&
                error.code === 'auth/popup-closed-by-user'
            ) {
                return;
            }

            if (error instanceof AxiosError && error.response?.status === 404) {
                try {
                    const { data: user } = await api.createUser({
                        name: firebaseUserCopy?.displayName!,
                        isApiDev,
                    });
                    setUser(user);
                    Sentry.setUser({
                        email: user.email,
                        id: user.id,
                        username: user.name,
                    });
                    return;
                } catch (error) {
                    signOut();
                    throw error;
                }
            }

            signOut();
            throw error;
        } finally {
            window.setTimeout = originalSetTimeout;
        }
    };

    const signUpWithEmailPass = async ({
        name,
        email,
        password,
        isApiDev,
    }: SignUpWithEmailParams) => {
        try {
            const { user: firebaseUser } = await createFirebaseUser(
                email,
                password
            );
            await Api.init(firebaseUser);
            const { data: user } = await api.createUser({ name, isApiDev });
            setUser(user);
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });
            setPlan('FREE');
            setIsSuperUser(false);
        } catch (error) {
            await signOut();
            throw error;
        }
    };

    const initLocalUserFromFbUser = async (firebaseUser: FirebaseUser) => {
        try {
            await Api.init(firebaseUser);
            const { data: user } = await api.getUser();
            // Api.setSuperUserKey(user.superUserKey!);
            setUser(user);
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });
            setPlan(user.plan);
            setIsSuperUser(Boolean(user?.features.SUPER));
        } catch (error) {
            signOut();
            throw error;
            //  handleError
        }
    };

    const signInWithEmailPass = async (email: string, password: string) => {
        try {
            const { user: firebaseUser } = await loginFirebaseUser(
                email,
                password
            );

            // logic to switch from email/pass login to MSF when a user tries to authenticate with MSF but already has an account
            //@ts-ignore
            if (window.MSF_FIREBASE_CREDENTIAL instanceof OAuthCredential) {
                const { user } = await linkWithCredential(
                    firebaseUser,
                    //@ts-ignore
                    window.MSF_FIREBASE_CREDENTIAL
                );
                const user2 = await unlink(user, 'password');
                //@ts-ignore
                window.MSF_FIREBASE_CREDENTIAL = undefined;
                alert(
                    'Your Microsoft account has been enabled. Loging in with username and password in no longer possible.'
                );
                await initLocalUserFromFbUser(user2);
            } else {
                await initLocalUserFromFbUser(firebaseUser);
            }
        } catch (error) {
            signOut();
            throw error;
            //  handleError
        }
    };

    const syncUser = async () => {
        const { data: user } = await api.getUser();
        setUser(user);
        setPlan(user.plan);
        setIsSuperUser(Boolean(user?.features.SUPER));
    };

    const signOut = async () => {
        await firebaseAuth.signOut();
        Api.signOut();
        setUser(null);
        Sentry.setUser(null);
        setPlan('FREE');
        setIsSuperUser(false);
        // reset();
    };

    // const resetPassword = (email: string) => {
    //     return sendFbPasswordResetEmail(email);
    // };

    const value = {
        user,
        signInWithGoogle,
        signInWithMSF,
        signOut,
        hasLoadedUser,
        signUpWithEmailPass,
        signInWithEmailPass,
        // resetPassword,
        setUser,
        plan,
        isSuperUser,
        syncUser,
        initLocalUserFromFbUser,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
