import { FieldMode, FieldTypes } from '@/types/types';
import { useTranslation } from 'react-i18next';

const useFieldsLables = (mode?: FieldMode) => {
    const { t } = useTranslation(['common', 'sign']);

    return {
        [FieldTypes.SIGNATURE]:
            mode === FieldMode.SIGNATURE
                ? `${t('sign:SignHere')}!`
                : t('Signature'),
        [FieldTypes.TEXT]: t('Text'),
        [FieldTypes.STAMP]: t('Stamp'),
        [FieldTypes.CHECKBOX]: t('Checkbox'),
        [FieldTypes.FILE]: t('FileAttachment'),
        [FieldTypes.TEXTAREA]: t('Textarea'),
        [FieldTypes.DATE]: t('Date'),
    };
};

export { useFieldsLables };
