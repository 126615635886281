import { useDrag } from 'react-dnd';
import styles from './SourceField.module.css';
import { DnDTypes, FieldTypes } from '@/types/types';
// import { fieldsLabels } from '@/constants';
import {
    AddFileIcon,
    CalendarIcon,
    CheckmarkIcon,
    MultiLineIcon,
    SignatureIcon,
    StampIcon,
    TextIcon,
} from '../icons';
import { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldsLables } from '@/hooks/useFieldsLabels';

export interface FieldProps {
    type: FieldTypes;
    className?: string;
    style?: CSSProperties;
    initialWidth?: number;
    initialHeight?: number;
}

export const SOURCE_FIELD_WIDTH = 150;
export const SOURCE_FIELD_HEIGHT = 40;

const SourceField = ({
    type,
    style,
    initialWidth,
    initialHeight,
}: FieldProps) => {
    const { t } = useTranslation(['editFields', 'buttons', 'common']);
    const fieldsLabels = useFieldsLables();

    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: DnDTypes.SOURCE_FIELD,
            item: {
                type,
                width: SOURCE_FIELD_WIDTH,
                height: SOURCE_FIELD_HEIGHT,
            },
        }),
        [type]
    );

    const [isClicking, setIsClicking] = useState(false);

    const [showHelper, setShowHelper] = useState(false);

    const hasBeenDragged = useRef(false);
    const timerRef = useRef<number | null>(null);

    const handleOnMouseUp = () => {
        setIsClicking(false);

        if (!hasBeenDragged.current) {
            setShowHelper(true);

            timerRef.current = window.setTimeout(() => {
                setShowHelper(false);
            }, 3000);
        }
    };

    const handleOnMouseDown = () => {
        setIsClicking(true);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }

        hasBeenDragged.current = false;
        setShowHelper(false);
    };

    const handleOnDrag = () => {
        hasBeenDragged.current = true;
        setIsClicking(false);
    };

    const handleTouchStart = () => {
        setIsClicking(true);
    };

    const handleTouchEnd = () => {
        setIsClicking(false);
    };

    return (
        <div
            style={{
                width: SOURCE_FIELD_WIDTH,
                minHeight: SOURCE_FIELD_HEIGHT,
                height: SOURCE_FIELD_HEIGHT,
                ...style,
                transition: 'all .2s ease-in-out',
                transform: isClicking ? 'scale(1.1)' : undefined,
            }}
            ref={drag}
            className={styles.container}
            onDragStart={handleOnDrag}
            onMouseDown={handleOnMouseDown}
            onMouseUp={handleOnMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={(e) => {
                e.preventDefault();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: '25px',
                    // backgroundColor: 'pink',
                }}
            >
                {type === FieldTypes.SIGNATURE && (
                    <SignatureIcon color="#0076CC" height={19} />
                )}
                {type === FieldTypes.TEXT && (
                    <TextIcon color="#0076CC" height={16} />
                )}
                {type === FieldTypes.STAMP && (
                    <StampIcon color="#0076CC" height={19} />
                )}
                {type === FieldTypes.CHECKBOX && (
                    <CheckmarkIcon color="#0076CC" height={13} />
                )}
                {type === FieldTypes.FILE && (
                    <AddFileIcon color="#0076CC" height={18} />
                )}
                {type === FieldTypes.TEXTAREA && (
                    <MultiLineIcon color="#0076CC" height={19} />
                )}
                {type === FieldTypes.DATE && (
                    <CalendarIcon color="#0076CC" height={17} />
                )}
            </div>
            <span className={styles.title}>{fieldsLabels[type]}</span>
            {showHelper && (
                <div className={styles.helperContainer}>
                    <span>{t('howToDrag')}</span>
                </div>
            )}
        </div>
    );
};
export default SourceField;
