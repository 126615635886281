import React from 'react';
import './App.css';

import { useAuth } from './contexts/Auth';
import Loading from './pages/Loading';
import lazyRetry from './utils/chuckLazyRetry';

const AuthRoutes = React.lazy(() =>
    lazyRetry(() => import('./routes/Auth'), 'Auth')
);
const AppRoutes = React.lazy(() =>
    lazyRetry(() => import('./routes/App'), 'App')
);

function App() {
    const { hasLoadedUser, user } = useAuth();

    if (!hasLoadedUser) return <Loading />;

    if (user)
        return (
            <React.Suspense fallback={<Loading />}>
                <AppRoutes />
            </React.Suspense>
        );

    return (
        <React.Suspense fallback={<Loading />}>
            <AuthRoutes />
        </React.Suspense>
    );
}

export default App;
