import { APP, DEFAULT_DOC_NAME } from '@/constants';
import { RecipientType, RecipientSettings } from '@/types/types';
import { Location } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);

export const toCamelCase = (text: string) => {
    const firstLetter = text.charAt(0);
    const rest = text.slice(1);

    return firstLetter.toUpperCase() + rest.toLowerCase();
};

export const isEmailValid = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
    );
};

export const isPasswordValid = (password: string) => {
    return password.length > 7;
};

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isDuplicate(value: string, array: string[]) {
    if (!value) return false;
    return array.filter((item) => item === value).length > 1;
}

export function areEmailsValid(array: string[]) {
    return array.every((email) => email === '' || isEmailValid(email));
}

export function isNonEmptyString(value: string) {
    return Boolean(value.replaceAll(' ', ''));
}

export function isEmptyString(value: string) {
    return !isNonEmptyString(value);
}

export function isValidRecipient({
    type,
    value,
}: {
    type: RecipientType;
    value: string;
}) {
    if (type === 'EMAIL') return isEmailValid(value);

    if (type === 'PHONE') return isPhoneNumberValid(value);

    if (type === 'SIGN_LINK_ONLY') return isRecipientNameValid(value);

    return false;
}

export function hasValidRecipients({
    recipients,
    recipientsTypes,
    recipientsNames,
    recipientsSettings,
}: {
    recipients: string[];
    recipientsTypes: RecipientType[];
    recipientsNames: string[];
    recipientsSettings: RecipientSettings[];
}) {
    const hasNonEmptySigners = recipients.some(
        (r, i) => isNonEmptyString(r) && !recipientsSettings[i].isCopyOnly
    );

    if (!hasNonEmptySigners) return false;

    return recipients.every((r, i) => {
        if (isEmptyString(r)) return true;

        return isValidRecipient({ value: r, type: recipientsTypes[i] });
    });
}

export function isRecipientNameValid(value: string = '') {
    return value.trim().length > 2;
}

export function isPhoneRecipientValid(phone: string, name: string) {
    return isPhoneNumberValid(phone) && isRecipientNameValid(name);
}

export function hasNoDuplicates(array: string[]) {
    return !array.some((element, index) => {
        return element !== '' && array.indexOf(element) !== index;
    });
}

export function trimExtension(str: string) {
    return str.replace(/\.[^/.]+$/, '');
}

export function getFileExtension(filename: string) {
    const extension = filename.split('.').pop();
    return extension?.toLocaleLowerCase();
}

export const getTextWidth = (
    text: string,
    fontSize: number,
    fontFamily: 'PlusJakartaSans' | 'Alex Brush'
) => {
    const canvas = new OffscreenCanvas(1, 1);
    const ctx = canvas.getContext('2d')!;
    ctx.font = `${fontSize}px ${fontFamily}`;

    const { width } = ctx.measureText(text);

    return width;
};

export const replaceMultipleSpaces = (string: string) => {
    return string.replace(/\s+/g, ' ');
};

export class LocationUrl {
    private pathname: string;
    private search: string;

    constructor({ pathname, search }: Location) {
        this.pathname = pathname;
        this.search = search;
    }

    get url() {
        return this.search ? `${this.pathname}${this.search}` : this.pathname;
    }

    upsertParam(name: string, value: string) {
        let params = new URLSearchParams(this.search);
        params.delete(name);
        params.append(name, value);
        this.search = `?${params.toString()}`;
    }
}

export const getPrevLocationUrl = (location: Location) => {
    const { prevLocation } = location.state as { prevLocation: Location };
    return new LocationUrl(prevLocation);
};

export const toDocName = (docUrl: string) => {
    const slashIndex = docUrl.lastIndexOf('/');
    const underscoreIndex = docUrl.lastIndexOf('__');

    const fileName = docUrl.slice(slashIndex + 1, underscoreIndex) + '.pdf';

    return fileName;
};

export const toFileName = (fileUrl: string) => {
    const slashIndex = fileUrl.lastIndexOf('/');
    const underscoreIndex = fileUrl.lastIndexOf('__');

    const fileName =
        fileUrl.slice(slashIndex + 1, underscoreIndex) +
        `.${getFileExtension(fileUrl)}`;

    return fileName;
};

export const pathToFileName = (path: string) => {
    const slashIndex = path.lastIndexOf('/');

    return path.slice(slashIndex + 1);
};

export function isValidUrl(string: string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

export const isValidRoNumber = (phoneNumber: string) => {
    const onlyNumberRegex = new RegExp('^[0-9]+$');
    return (
        phoneNumber.length === 10 &&
        phoneNumber.startsWith('07') &&
        onlyNumberRegex.test(phoneNumber)
    );
};

export const isValidInterNumber = (phoneNumber: string) => {
    const onlyNumberRegex = new RegExp('^[0-9]+$');

    const numbers = phoneNumber.slice(1);

    return (
        phoneNumber.startsWith('+') &&
        numbers.length > 8 &&
        numbers.length < 14 &&
        onlyNumberRegex.test(numbers)
    );
};

export const isPhoneNumberValid = (number: string) => {
    return isValidRoNumber(number) || isValidInterNumber(number);
};

export const textToCsvDownload = (text: string) => {
    const link = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = 'table.csv';
    link.click();
    URL.revokeObjectURL(link.href);
};

export const textToFileDownload = ({
    text,
    fileName,
    ext = 'txt',
}: {
    text: string;
    fileName: string;
    ext?: string;
}) => {
    const link = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = `${fileName}.${ext}`;
    link.click();
    URL.revokeObjectURL(link.href);
};

export const hasDuplicates = (arr: string[]) =>
    new Set(arr).size !== arr.length;

export const extractFileNameFromHeader = (
    contentDispositionHeader: string | null
): string => {
    if (!contentDispositionHeader) return DEFAULT_DOC_NAME;

    const regExpFilename = /filename="(?<filename>.*)"/;

    return (
        regExpFilename.exec(contentDispositionHeader)?.groups?.filename ??
        DEFAULT_DOC_NAME
    );
};

export const extractFilenameForCertificate = (header: string) => {
    const match = header.match(/filename="?(.*?)"?$/);
    const fileName = match ? match[1] : 'Certificate.pdf';
    return fileName;
};

export function docUrlToFileName(docUrl: string) {
    return docUrl.slice(docUrl.indexOf('/') + 1);
}

export const dayJsWithTz = (date: string) => {
    if (APP === 'ESEMNEAZA') {
        return dayjs(date).tz('Europe/Bucharest');
    } else {
        dayjs.tz.guess();
        return dayjs(date);
    }
};

export function maskPhoneNumber(phoneNumber: string) {
    // Remove non-digit characters (optional, based on your input format)
    const cleaned = phoneNumber.replace(/\D/g, '');

    // Check if the number is long enough to mask
    if (cleaned.length < 4) {
        return cleaned; // Return the number as is if less than 4 digits
    }

    // Create the masked number, showing the last 4 digits
    const masked = cleaned.slice(0, -4).replace(/./g, '*') + cleaned.slice(-4);

    return masked;
}
